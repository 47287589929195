let pageSettingPostUrl = '';



let updateSettings = function(){

    let isUnderline = $('#settingsUnderline').is(':checked') ? true : false;
    let isInverse = $('#settingsInverse').is(':checked') ? true : false;
    let isStyle = $('#settingsUnstyle').is(':checked') ? true : false;
    let fontSize = $('html').css( "font-size" );

    /*$.post(pageSettingPostUrl, {
        underline: isUnderline,
        Inverse:isInverse,
        style:isStyle,
        font:fontSize
    });*/
};

$("#resetControls").on('click', function () {
    Cookies.remove('isUnderline');
    Cookies.remove('isInvert');
});


// check box for UnderLine ------------------------------------
$('#settingsUnderline').on('change',function () {
    settingsUnderline(this);
    updateSettings();
});

let settingsUnderline = function ($this) {
    if($($this).is(':checked')){
        $("body").addClass('isUnderLine');
        Cookies.set('isUnderline', "checked");
    }else{
        $("body").removeClass('isUnderLine');
        Cookies.remove('isUnderline');
    }
};

if(Cookies.get('isUnderline') == "checked"){
    $('#settingsUnderline').attr( "checked" , "checked" );
    $("body").addClass('isUnderLine');
}


// check box for Inverse ------------------------------------

$('#settingsInverse').on('change',function () {
    if($(this).is(':checked')){
        $("body").addClass('isInvert');
        Cookies.set('isInvert', "checked");
    }else{
        $("body").removeClass('isInvert');
        Cookies.remove('isInvert');
    }
    updateSettings();
});

if(Cookies.get('isInvert') == "checked"){
    $('#settingsInverse').attr( "checked" , "checked" );
    $("body").addClass('isInvert');
}



// When + or - buttons are clicked the font size of the h1 is increased/decreased by 2
// The max is set to 50px for this demo, the min is set by min font in the user's style sheet

let fSize = 16;
function getSize() {
    fSize = $('html').css( "font-size" );
    fSize = parseInt(fSize, 10);
    Cookies.set('fSize', fSize);
    updateSettings();
}

//get inital font size
getSize();

$( "#settingsTextUp" ).on( "click", function() {
    // parse font size, if less than 50 increase font size
    if ((fSize + 2) <= 30) {
        $( "html" ).css( "font-size", "+=2" );
    }
});

$( "#settingsTextDown" ).on( "click", function() {
    if ((fSize - 2) >= 12) {
        $( "html" ).css( "font-size", "-=2" );
    }
});


