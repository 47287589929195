
$("#sportsFilter input:checkbox").on("change", function() {
    var selectedClass = [];
    $("#sportsFilter input:checkbox").each(function () {
        if($(this).is(':checked')){
            selectedClass.push($(this).data("filter"));
        }
    });
    //console.log('selectedClass',selectedClass);
    //selectedClass = $(this).data("filter");

    $("#sportsList > div").each(function () {
        var filterName = [];
        //console.log(String(filterName));
        var tags = $(this).attr('data-filter');

        if (tags) {
            tags.split(',').forEach(function(name) {
                filterName.push(name);
            })
        }
        //let intersection = selectedClass.filter(element => filterName.includes(element));
        //console.log('filterName', filterName);
        //console.log('intersection', intersection);
        for(var i = 0 , len = filterName.length; i < len; i++){
            if($.inArray(filterName[i], selectedClass) != -1) {
                $(this).fadeIn().addClass('d-md-flex');
                break;
            }else{
                $(this).fadeOut().removeClass('d-md-flex');
            }
        }
    });



});