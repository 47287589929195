
    let isOther = 'other';
    $('#donateAmount').on('change',function () {
            if($(this).val() === isOther){ // EDITED THIS LINE
                //$(this).attr("selected","selected");
                $('#donateAmount_other_input_wrap').fadeIn();
            }else {
                $('#donateAmount_other_input_wrap').fadeOut();
            }
    });
    $('#monthlyDonateAmount').on('change',function () {
        if($(this).val() === isOther){ // EDITED THIS LINE
            //$(this).attr("selected","selected");
            $('#monthlyDonateAmount_other_input_wrap').fadeIn();
        }else {
            $('#monthlyDonateAmount_other_input_wrap').fadeOut();
        }
    });

